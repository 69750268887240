<script>
import Layout from "../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import ECharts from "vue-echarts";

import "echarts/lib/chart/line";

import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/grid";
import { lineChart } from "./data";
/**
 * Add-product component
 */
export default {
  page: {
    title: "Profile Manager",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    "v-chart": ECharts,
    Layout,
    PageHeader,
  },
  data() {
    return {
      lineChart: lineChart,
      title: "Profile Manager",
      items: [
        {
          text: "Profile Manager",
        },
        {
          text: "Profile Manager",
          active: true,
        },
      ],
      form: {
        partTime: null,
        checked: [],
      },
      partTimeOptions: [
        { text: "Part Time Team", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,

      userList: [
        {
          id: "#MN0131",

          name: "Connie Franco",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
        {
          id: "#MN0130",

          name: "Paul Reynolds",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
        {
          id: "#MN0129",

          name: "Ronald Patterson",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assigned",
        },
        {
          id: "#MN0128",

          name: "Adella Perez",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
        {
          id: "#MN0127",
          name: "Theresa Mayers",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
        {
          id: "#MN0126",
          name: "Michael Wallace",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assigned",
        },
        {
          id: "#MN0125",
          name: "Oliver Gonzales",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assigned",
        },
        {
          id: "#MN0124",
          name: "David Burke",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
        {
          id: "#MN0123",
          name: "Willie Verner",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
        {
          id: "#MN0122",
          name: "Felix Perry",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
        {
          id: "#MN0121",
          name: "Virgil Kelley",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
        {
          id: "#MN0120",
          name: "Matthew Lawler",
          userID: "XSHD123",
          title: "Manager",
          email: "test@gmail.com",
          contact: "+1 6587 3912",
          status: "Assign",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "id",
          label: "Sr.#",
          sortable: true,
        },

        {
          key: "name",
          label: "User Name",
          sortable: true,
        },
        {
          key: "userID",
          label: "User ID",
          sortable: true,
        },
        {
          key: "title",
          label: "Title",
          sortable: true,
        },
        {
          key: "email",
          label: "User Email",
          sortable: true,
        },
        {
          key: "contact",
          label: "Contact",
          sortable: true,
        },

        {
            key: "action", 
            label: "Action"
        }
      ],
    };
  },

    computed: {
    /**
     * Total no. of records
     */
    rows() {
        console.log(this.userList.length);
      return this.userList.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.userList.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="row align-items-center mb-2">
      <div class="col-xl-2 col-md-12 mb-xl-0 mb-3">
        <h2 class="f-16 pb-0 mb-0 fw-bold poppins">{{ title }}</h2>
      </div>
      <div class="col-xl-10 col-md-12 mb-xl-0 mb-3">
        <b-form v-if="show" class="profile-manager-form">
          <div
            class="
              d-flex
              flex-md-row flex-column
              justify-content-xl-end justify-content-lg-start
              w-100
              gap-2
            "
          >
            <b-form-group>
              <b-form-select
                class="w-100"
                v-model="form.partTime"
                :options="partTimeOptions"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group>
              <b-form-select
                class="w-100"
                v-model="form.partTime"
                :options="partTimeOptions"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group>
              <b-form-select
                class="w-100"
                v-model="form.partTime"
                :options="partTimeOptions"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group>
              <b-form-select
                class="w-100"
                v-model="form.partTime"
                :options="partTimeOptions"
                required
              ></b-form-select>
            </b-form-group>
            <b-button class="px-4 gradientColor border-0 radius-30"
              >Assign Shift</b-button
            >
          </div>
        </b-form>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-xl-5 col-md-12">
        <div class="p-3 radius-20 border bg-white card">
          <div class="d-flex justify-content-between">
            <div
              class="
                profile-info__wrap
                d-flex
                flex-md-row flex-column
                gap-3
                align-items-md-center align-items-start
              "
            >
              <div class="profile-img__wrap">
                <img
                  src="@/assets/images/profile-manager/profile-img.png"
                  alt=""
                />
              </div>
              <div class="profile-person-info">
                <h3 class="f-16 mb-0 pb-0 fw-bold poppins">John Simon</h3>
                <p class="mb-0 pb-0 f-12">Manager</p>
                <p class="mb-0 pb-0 f-12">john@shiftfish.com</p>
                <p class="mb-0 pb-0 f-12">+1 6587 3912</p>
                <p class="mb-0 pb-0 f-12">ID-CFA67890</p>
              </div>
            </div>
            <div class="hamburger-menu">
              <i class="mdi mdi-dots-horizontal f-20"></i>
            </div>
          </div>
          <div class="inner-boxes">
            <div class="row mt-3">
              <div class="col-md-4 mb-md-0 mb-3">
                <div
                  class="
                    box-wrap
                    bg-white
                    finished-total
                    text-center
                    py-4
                    radius-20
                  "
                >
                  <span class="d-block">
                    <i class="mdi mdi-check-all f-20 text-success"></i
                  ></span>
                  <span class="d-block fw-bold f-20">100</span>
                  <span class="d-block">Finished Total</span>
                </div>
              </div>
              <div class="col-md-4 mb-md-0 mb-3">
                <div class="box-wrap finished-total text-center py-4 radius-20">
                  <span class="d-block">
                    <i
                      class="mdi mdi-check-circle-outline f-20 text-warning"
                    ></i
                  ></span>
                  <span class="d-block fw-bold f-20">100</span>
                  <span class="d-block">Delivered Total </span>
                </div>
              </div>

              <div class="col-md-4 mb-md-0 mb-3">
                <div class="box-wrap finished-total text-center py-4 radius-20">
                  <span class="d-block">
                    <i class="mdi mdi-information-outline f-20 text-danger"></i
                  ></span>
                  <span class="d-block fw-bold f-20">100</span>
                  <span class="d-block">Canceled Total </span>
                </div>
              </div>
            </div>

            <div class="box-wrap radius-20 mt-md-3 mt-0 p-md-5 px-2 py-4">
              <div class="row">
                <div class="col-md-6 col-6">
                  <div class="d-flex gap-3 flex-md-row flex-column">
                    <div class="icon-wrapper text-md-start text-center">
                      <img
                        src="@/assets/images/profile-manager/icon-01.png"
                        alt=""
                      />
                    </div>
                    <div class="text-info__wrapper">
                      <span class="d-block text-md-start text-center"
                        >Total Trip</span
                      >
                      <span
                        class="d-block text-md-start text-center fw-bold f-20"
                        >15</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div
                    class="
                      d-flex
                      gap-3
                      flex-md-row flex-column
                      justify-content-md-end justify-content-center
                    "
                  >
                    <div class="icon-wrapper text-md-start text-center">
                      <img
                        src="@/assets/images/profile-manager/icon-02.png"
                        alt=""
                      />
                    </div>
                    <div class="text-info__wrapper">
                      <span class="d-block text-md-start text-center"
                        >Total Trip</span
                      >
                      <span
                        class="d-block fw-bold f-20 text-md-start text-center"
                        >15</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4 col-4 text-center">
                  <span class="d-block">Total Trip</span>
                  <span class="d-block fw-bold f-20">15</span>
                </div>
                <div class="col-md-4 col-4 text-center">
                  <span class="d-block">Total Trip</span>
                  <span class="d-block fw-bold f-20">15</span>
                </div>
                <div class="col-md-4 col-4 text-center">
                  <span class="d-block">Total Trip</span>
                  <span class="d-block fw-bold f-20">15</span>
                </div>
              </div>
            </div>
            <div class="box-wrap py-3 px-5 radius-20 mt-3">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="d-flex gap-3 flex-md-row flex-column">
                    <div class="icon-wrapper text-md-start text-center">
                      <img
                        src="@/assets/images/profile-manager/icon-03.png"
                        alt=""
                      />
                    </div>
                    <div class="text-info__wrapper text-md-start text-center">
                      <span class="d-block">Total Trip</span>
                      <span class="d-block fw-bold f-20">15</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="
                      d-flex
                      gap-3
                      justify-content-md-end justify-content-center
                      align-items-center
                    "
                  >
                    <div class="icon-wrapper">
                      <p class="p-0 m-0">Send Bonus</p>
                    </div>
                    <div class="icon__wrapper">
                      <span
                        ><i class="mdi mdi-chevron-right-box-outline f-20"> </i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7 col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="box-wrap card bg-white text-center py-4 radius-20">
              <div class="performance-wrap">
                <img src="@/assets/images/profile-manager/icon-04.png" alt="" />
              </div>
              <span class="d-block fw-bold f-20">100</span>
              <span class="d-block">Performance</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="box-wrap card bg-white text-center py-4 radius-20">
              <div class="performance-wrap">
                <img src="@/assets/images/profile-manager/icon-05.png" alt="" />
              </div>
              <span class="d-block fw-bold f-20">100</span>
              <span class="d-block">Min.Performance</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="box-wrap card bg-white text-center py-4 radius-20">
              <div class="performance-wrap">
                <img src="@/assets/images/profile-manager/icon-06.png" alt="" />
              </div>
              <span class="d-block fw-bold f-20">100</span>
              <span class="d-block">Avg.Performance</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card radius-20 px-3 py-4">
              <h4 class="mb-4 poppins">Performance Statistic</h4>
              <v-chart :options="lineChart" autoresize width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive mb-0">
        <table class="table table-stripped"  
        :per-page="perPage"
        :current-page="currentPage"
        
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered">
          <thead>
            <tr>
              <th v-for="(field, index) in fields" :key="index">
                {{ field.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in userList" :key="index">
              <td>{{ user.id }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.userID }}</td>
              <td>{{ user.title }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.contact }}</td>
              <td> <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
                href="javascript:void(0);"
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Edit"
              >
                <i class="uil uil-pen font-size-18"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                href="javascript:void(0);"
                class="px-2 text-danger"
                v-b-tooltip.hover
                title="Delete"
              >
                <i class="uil uil-trash-alt font-size-18"></i>
              </a>
            </li>
                <li class="list-inline-item">
              <a
                href="javascript:void(0);"
                class="px-2 text-dark"
                v-b-tooltip.hover
                :title="user.status"
              >
                <i class="uil uil-user font-size-18" :class="{'uil uil-check text-info': user.status === 'Assigned' }"></i> {{user.status}}
              </a>
            </li>
          </ul></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
        <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
